.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.adaptive-menu-content{
  margin: 0 !important;
  max-width: 100% !important;
  top: 88px;
}

.adaptive-menu-content .ant-modal-content{
  border-radius: 0 !important;
  padding: 20px 20px 6px 20px !important;
}

.adaptive-menu-content .ant-modal-close{
  display: none !important;
}

.routeLink{
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #FFFFFF;
}

.routeLink:hover{
  cursor: pointer;
}

.custom-message .ant-message-notice-content{
  background: #f8d7da !important;
  border: 1px solid #f5c2c7;
}
.custom-message .ant-message-notice-content span:not(.anticon-close-circle) {
  color: #842029 !important;
}

.ant-form-item-control .ant-input::placeholder, .ant-form-item-control .ant-select-selection-placeholder {
  color: #1D1D1F;
  font-size: 16px;
}

.ant-modal-title{
  font-size: 40px !important;
  line-height: 48px !important;
  margin-bottom: 48px !important;
  margin-top: 16px !important;
}

.ant-modal-content{
  border-radius: 30px !important;
  padding: 30px !important;
}

.ant-modal-close{
  display: flex !important;
  padding: 16px !important;
  border-radius: 30px !important;
  top: 48px !important;
  right: 40px !important;
  align-items: center !important;
  justify-content: center !important;
  width: 56px !important;
  height: 56px !important;
  background-color: rgba(245, 246, 247, 1) !important;
}

/*.custom-form .ant-form-item-control{*/
  /*height: 100px;*/
/*}*/

/*.custom-form .ant-form-item{*/
  /*margin-bottom: 0;*/
/*}*/

.ant-form-item-control .ant-input{
    border-color: rgba(229, 231, 232, 1);
    font-size: 14px !important;
    font-family: Inter;
}

.ant-form-item-control .ant-input::placeholder{
   color: #1D1D1F !important;
   font-size: 16px !important;
}



.ant-input-outlined:focus {
  box-shadow: 0 0 0 2px rgba(255, 5, 25, 0.1);
}

@media (max-width: 1073px) {
  .routeLink{
    font-size: 16px;
  }
}

@media (max-width: 938px) {
  .routeLink{
    color: #1D1D1F;
  }
}

@media (max-width: 624px) {
  .ant-modal-title{
    font-size: 32px !important;
  }


}

@media (max-width: 577px) {
  .ant-modal-title{
    font-size: 24px !important;
  }

  .ant-modal-close{
    top: 28px !important;
    right: 20px !important;
    width: 48px !important;
    height: 48px !important;
  }

  .adaptive-menu-content{
    top: 71px;
  }

  .routeLink{
    line-height: 50px;
  }
}

@media(max-width: 330px){
  .ant-modal-title{
    margin-bottom: 22px !important;
  }
}

@media(max-width: 400px){
  .ant-btn:not(.backButton):not(.adaptive-menu):not(.payment-btn):not(.ant-btn-link){
    height: 60px !important;
    padding-left: 16px;
    padding-right: 16px;
  }
}





.ant-modal-close svg{
    width: 20px;
    height: 20px;
}

.ant-modal-root .ant-modal-mask{
  background-color: rgba(0, 14, 47, 0.85);
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
